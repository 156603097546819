exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-posts-index-tsx": () => import("./../../../src/pages/posts/index.tsx" /* webpackChunkName: "component---src-pages-posts-index-tsx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-posts-24-08-02-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/posts/24-08-02/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-posts-24-08-02-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-posts-new-feature-comment-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/posts/new-feature-comment/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-posts-new-feature-comment-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-posts-renewal-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/posts/renewal/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-posts-renewal-index-mdx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */)
}

